import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumb from "../components/dataschema/breadcrumb";
import { SRLWrapper } from "simple-react-lightbox";

//add fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix")
  .map(icon => Icons[icon])

library.add(...iconList);

//options for image lightbox
const options = {
  settings: {

  },
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
    showAutoplayButton: false,
    disablePanzoom: false,
    disableWheelControls: false,
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: true,
  }
};

const WebPost = ({ data }) => {
  const { title, body, technology, link, imageGallery } = data.contentfulWeb;


  return (
    <Layout>
      <SEO title={title} keywords={[`gatsby`, `application`, `react`]} />
      <div className="container webPage">
        <div className="row">
          <div className="col">
          <nav aria-label="breadcrumb" className="custom-breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="/web">web</a></li>
                <li class="breadcrumb-item active" aria-current="page">{title}</li>
              </ol>
            </nav>
          </div>
          </div>
          <div className="row">
          <div className="col imageGallery">
            <SRLWrapper options={options}>
              {imageGallery.map((item) => (
                <div className="tiles" key={item.id}>
                  <a href={item.fluid.src} data-attribute="SRL">
                    <img src={item.fixed.src} alt={item.title} className="img-fluid" />
                  </a>
                </div>
              ))}
              </SRLWrapper>

          </div>
          </div>
          <div className="row">
          <div className="col-8 d-flex align-items-center bd-highlight mb-3">
            <div className="rw">
              <div className="body-text"
              dangerouslySetInnerHTML={{
                __html: '<h1>'+title+'</h1>' + body.childMarkdownRemark.html,
              }}
            ></div>
          [ <a href={link}>visit site</a> ]
          </div>
          </div>
          <div className="col-4">
            <h3>technology</h3>
            {technology.map((item) => (
                <div className="d-flex technology" key={item.id}>
                  <FontAwesomeIcon icon={[item.fontawesomeTypes === 'solid' ? 'fa' : 'fab', item.fontawesomeName]} />
                 <span>{item.technologyName}</span>
                </div>
              ))}
          </div>
   
        </div>


      </div>
      <Breadcrumb lvl1_name="home" lvl1_url={typeof window !== 'undefined' ? window.location.origin : ''} lvl2_url={typeof window !== 'undefined' ? window.location.origin  + '/web' : ''} lvl2_name="Web" lvl3_name={title} lvl3_url={typeof window !== 'undefined' ? window.location.href : ''} />
   
    </Layout>


  );
};
export default WebPost;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulWeb(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      link
      technology {
        technologyName
        id
        fontawesomeTypes
        fontawesomeName
      }
      imageGallery {
        fixed(height: 250, width: 450, quality:100) {
          src
        }
        fluid(maxWidth: 2000, quality: 100) {
          src
        }
        id
        title
        file {
          url
        }
      }
      heroImage {
        fluid(maxHeight: 500, maxWidth: 900) {
          src
        }
        fixed(height: 400, width: 2000, quality:100) {
          src
        }
        file {
          url
        }
      }
    }
    category : contentfulBlogpostCategory {
      blogpostCategoryName
    }

    allContentfulBlogpostCategory {
      edges {
        node {
          blogpostCategoryName
        }
      }
    }
    contentfulPageHeader(title: {eq: "web"}) {
      heroImage {
        fixed(width: 2000, height: 500) {
          src
        }
        title
      }
      title
      body {
        body
      }
    }
  }
  
`;
